<template>
    <div>
        <v-dialog v-model="olahDataDialog" persistent max-width="80%">
            <v-card>
                <v-card-title class="text-h5 font-weight-bold">
                    Edit Surat Jalan Sampel
                </v-card-title>
                <v-divider class="mx-5"></v-divider>

                <v-card-text class="mt-3">
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col>
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Nomor Surat Jalan
                                </v-card-title>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="olahData.nomer_seri"
                                    :rules="[rules.required]"
                                    readonly
                                    filled
                                    hide-details
                                ></v-text-field>

                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Tanggal
                                </v-card-title>
                                <v-menu
                                    v-model="dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-left="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="olahData.tgl"
                                            append-icon="mdi-calendar-month"
                                            placeholder="Pilih tanggal"
                                            readonly
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            :rules="[rules.required]"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="picker"
                                        v-model="olahData.tgl"
                                        @input="dateMenu = false"
                                    >
                                    </v-date-picker>
                                </v-menu>

                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Gudang Asal
                                </v-card-title>
                                <v-select
                                    placeholder="Gudang Asal"
                                    dense
                                    outlined
                                    v-model="olahData.gudang_asal_id"
                                    :items="listGudang"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    readonly
                                    filled
                                ></v-select>

                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Penerima
                                </v-card-title>
                                <v-autocomplete
                                    placeholder="Penerima"
                                    dense
                                    outlined
                                    v-model="olahData.customer_id"
                                    :items="dataCustomer"
                                    item-text="nama"
                                    item-value="id"
                                    :rules="[rules.required]"
                                ></v-autocomplete>
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Note (Opsional)
                                </v-card-title>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="olahData.notes"
                                    hide-details
                                    :maxlength="maxnote"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Nomor Kendaraan (Opsional)
                                </v-card-title>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="olahData.no_kendaraan"
                                ></v-text-field>

                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Dibuat Oleh
                                </v-card-title>
                                <v-select
                                    v-model="olahData.dibuat_id"
                                    :items="listNama"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    dense
                                    outlined
                                ></v-select>

                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Disetujui Oleh
                                </v-card-title>
                                <v-select
                                    v-model="olahData.disetujui_id"
                                    :items="listNama"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    dense
                                    outlined
                                ></v-select>

                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Dikeluarkan Oleh
                                </v-card-title>
                                <v-select
                                    v-model="olahData.dikeluarkan_id"
                                    :items="listNama"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    List Material Kirim
                                </v-card-title>
                                <v-data-table
                                    :headers="headersListMaterial"
                                    :items="selectMaterial"
                                    dense
                                >
                                    <template v-slot:item.total="{ item }">
                                        <v-col md="4">
                                            <v-text-field
                                                v-model="item.total"
                                                outlined
                                                dense
                                                hide-details
                                                persistent-hint
                                                single-line
                                                :rules="[rules.required]"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </template>
                                    <template v-slot:item.remove="{ index }">
                                        <v-btn
                                            color="error"
                                            x-small
                                            class="text-caption white--text mr-2 my-1"
                                            @click.prevent="remove(index)"
                                        >
                                            <v-icon small
                                                >mdi-close-thick</v-icon
                                            >
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-5"></v-divider>

                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    placeholder="Search"
                                    dense
                                    outlined
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-data-table
                                    v-model="olahData.selectMaterial"
                                    item-key="id"
                                    show-select
                                    :headers="headersMaterial"
                                    :items="indexedItems"
                                    :search="search"
                                    :items-per-page="itemsPerPage"
                                    :loading="loadingTable"
                                    :page.sync="page"
                                    hide-default-footer
                                    dense
                                >
                                    <template v-slot:top>
                                        <v-row>
                                            <v-col cols="2">
                                                <v-select
                                                    v-model="itemsPerPage"
                                                    :items="showPerPage"
                                                    outlined
                                                    dense
                                                    label="items per page"
                                                    v-on:change="
                                                        changeItemPerPage
                                                    "
                                                >
                                                </v-select>
                                            </v-col>
                                            <v-col align="right">
                                                <span class="mr-15 text-caption"
                                                    >Page: {{ page }}</span
                                                >
                                                <v-btn
                                                    fab
                                                    x-small
                                                    depressed
                                                    color="primary"
                                                    class="mx-3"
                                                    @click="prevPage"
                                                >
                                                    <v-icon dark>
                                                        mdi-chevron-left
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    fab
                                                    x-small
                                                    depressed
                                                    color="primary"
                                                    class="mx-3"
                                                    @click="nextPage"
                                                >
                                                    <v-icon dark>
                                                        mdi-chevron-right
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template
                                        v-slot:item.total_stock="{ item }"
                                    >
                                        <span>
                                            {{ getQtyStock(item) }}
                                        </span>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="#61B15A"
                        style="text-transform: unset !important"
                        @click="closeOlahData"
                    >
                        Batal
                    </v-btn>
                    <v-btn
                        color="#61B15A"
                        class="white--text"
                        style="text-transform: unset !important"
                        @click="update"
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Simpan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="popDialog" width="500">
            <v-card color="white" rounded>
                <v-card-title class="justify-center text-h6 black--text">
                    {{ popTitle }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light-green darken-1"
                        class="white--text"
                        @click="closePopDialog"
                        >Tutup</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//import { mapActions } from "vuex";
import axios from "axios";

export default {
    props: {
        olahDataDialog: Boolean,
        olahData: Object,
        dataCustomer: Array,
        listGudang: Array,
        moving_state_id: String,
        listNama: Array,
    },

    data: () => ({
        maxnote: 40,
        valid: false,
        loading: false,
        loadingTable: false,
        page: 1,
        itemsPerPage: 10,
        showPerPage: [5, 10, 20, 50, 100, 250, 500],
        dateMenu: false,
        search: "",
        rules: {
            required: (value) => !!value || "Tidak boleh kosong !!",
            maxQty(value, stock, callbackDivider) {
                var max = stock;
                const divider = callbackDivider();
                max = stock / divider;
                return Number(value || "") <= Number(max) || `Maximal ${max}`;
            },
        },
        selectMaterial: [],
        list_summary: [],
        headersListMaterial: [
            {
                text: "Hapus",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "remove",
            },
            {
                text: "Material",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "material_code",
            },
            {
                text: "Batch",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "batch_no",
            },
            {
                text: "Satuan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "satuan",
            },
            {
                text: "Quantity Kirim",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "total",
            },
        ],
        headersMaterial: [
            {
                text: "Material",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "material_code",
            },
            {
                text: "Batch",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "batch_no",
            },
            {
                text: "Satuan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "satuan",
            },
            {
                text: "Quantity Stock",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "total_stock",
            },
        ],
        dataMaterial: [],
        check: 0,
        popDialog: false,
        popTitle: "",
        key: 0,
    }),

    watch: {
        olahDataDialog(visible) {
            if (visible) {
                this.selectMaterial = this.olahData.selectMaterial;
                this.getMaterial();
            }
        },
    },

    computed: {
        indexedItems() {
            return this.dataMaterial.map((item, index) => ({
                id: index,
                ...item,
            }));
        },
    },

    methods: {
        update() {
            this.loading = true;
            this.olahData.no_kendaraan = this.olahData.no_kendaraan.toUpperCase();
            this.olahData.moving_state_id = this.moving_state_id;
            this.list_summary = [];

            var lists = {};
            let resultToReturn = false;
            let validQty = true;
            let qtyValid = true;

            for (let i = 0; i < this.selectMaterial.length; i++) {
                for (let j = 0; j < this.selectMaterial.length; j++) {
                    if (i !== j) {
                        if (
                            this.selectMaterial[i].batch_no ===
                                this.selectMaterial[j].batch_no &&
                            this.selectMaterial[i].satuan ===
                                this.selectMaterial[j].satuan
                        ) {
                            resultToReturn = true;
                            break;
                        }
                    }
                }
                if (resultToReturn) {
                    break;
                }
            }

            if (resultToReturn) {
                this.popTitle = "Duplicate Material !!!";
                this.popDialog = true;
                this.loading = false;
            } else {
                for (let i = 0; i < this.selectMaterial.length; i++) {
                    for (let l = 0; l < this.dataMaterial.length; l++) {
                        if (
                            this.selectMaterial[i].type_box_id ===
                                this.dataMaterial[l].type_box_id &&
                            this.selectMaterial[i].batch_no ===
                                this.dataMaterial[l].batch_no
                        ) {
                            let stock = this.dataMaterial[l].total_stock || 0;
                            const divider = this.getItemStockDivider(
                                this.dataMaterial[l].satuan,
                                this.dataMaterial[l].mat_code,
                                this.dataMaterial[l].is_meta
                            );

                            stock /= divider;

                            if (this.selectMaterial[i].total > Number(stock)) {
                                validQty = false;
                                break;
                            } else {
                                validQty = true;
                            }
                        }
                    }
                    if (validQty) {
                        if (this.selectMaterial[i].total === "0") {
                            qtyValid = false;
                            break;
                        } else {
                            qtyValid = true;
                        }
                        if (
                            this.selectMaterial[i].total_diterima === undefined
                        ) {
                            this.selectMaterial[i].total_diterima = "0";
                        }
                        lists.batch_no = this.selectMaterial[i].batch_no;
                        lists.type_box_id = this.selectMaterial[i].type_box_id;
                        lists.total = this.selectMaterial[i].total;
                        lists.total_diterima = this.selectMaterial[
                            i
                        ].total_diterima;
                        this.list_summary.push(lists);
                        lists = {};
                    } else {
                        qtyValid = false;
                    }
                    if (!qtyValid) {
                        break;
                    }
                }

                if (!validQty) {
                    this.popTitle = "Quantity tidak valid !!!";
                    this.popDialog = true;
                    this.loading = false;
                } else {
                    validQty = !validQty;
                    const summs = {};
                    summs.id = this.olahData.id;
                    summs.list_summary = this.list_summary;
                    if (summs.list_summary.length !== 0) {
                        if (
                            this.olahData.gudang_asal_id ===
                            this.olahData.gudang_penerima_id
                        ) {
                            this.popTitle =
                                "Gudang Asal dan Gudang Penerima Tidak Boleh Sama !!!";
                            this.check = 0;
                            this.popDialog = true;
                            this.loading = false;
                        } else {
                            if (qtyValid) {
                                const summsSend = JSON.stringify(summs);
                                axios.put(
                                    "v1/admin/surat_jalan/summ",
                                    summsSend,
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    }
                                );
                                const data = JSON.stringify(this.olahData);
                                axios
                                    .put("v1/admin/surat_jalan/sample", data, {
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    })
                                    .then(() => {
                                        this.loading = false;
                                        this.$emit("reloadOlahData");
                                        this.closeOlahData();
                                        this.list_summary = [];
                                    })
                                    .catch((error) => {
                                        if (error.response) {
                                            this.popTitle =
                                                "Update Error. Periksa kembali inputan anda !";
                                            this.popDialog = true;
                                            this.loading = false;
                                        }
                                    });
                            } else {
                                this.popTitle = "Qty Material Tidak Boleh 0 !";
                                this.popDialog = true;
                                this.loading = false;
                            }
                        }
                    } else {
                        this.popTitle =
                            "List/Qty Material Tidak Boleh Kosong !";
                        this.popDialog = true;
                        this.loading = false;
                    }
                }
            }
        },
        getMaterial() {
            this.dataMaterial = [];
            this.loadingTable = true;
            axios
                .get(
                    "v1/admin/laporan/gudang/list?gudang_id=" +
                        this.olahData.gudang_asal_id +
                        "&limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page
                )
                .then((response) => {
                    this.dataMaterial = response.data.data;
                    this.selectMaterial.forEach((e) => {
                        this.dataMaterial.forEach((r, i) => {
                            if (
                                e.batch_no === r.batch_no &&
                                e.satuan === r.satuan
                            ) {
                                this.dataMaterial[i].total = e.total;
                                this.dataMaterial[i].qty_kirim = "";
                            }
                        });
                    });
                    this.loadingTable = false;
                });
        },
        changeItemPerPage() {
            if (this.dataMaterial.length === 0) {
                return;
            } else {
                this.getMaterial();
            }
        },
        prevPage() {
            if (this.page === 1) {
                return;
            } else {
                this.page = this.page - 1;
                this.getMaterial();
            }
        },
        nextPage() {
            if (this.dataMaterial.length === 0) {
                return;
            } else {
                this.page = this.page + 1;
                this.getMaterial();
            }
        },
        async populateDataNama(value) {
            value.forEach((element) => {
                if (element.is_activated === 1) {
                    axios
                        .get(
                            "v1/admin/signature_type?id=" +
                                element.signature_type_id
                        )
                        .then((response) => {
                            if (response.data.data.kode === "DIBUAT_OLEH") {
                                this.olahData.dibuat_id = element.id;
                            }
                            if (response.data.data.kode === "DISETUJUI_OLEH") {
                                this.olahData.disetujui_id = element.id;
                            }
                            if (
                                response.data.data.kode === "DIKELUARKAN_OLEH"
                            ) {
                                this.olahData.dikeluarkan_id = element.id;
                            }
                        });
                }
            });
        },
        remove(index) {
            this.selectMaterial.splice(index, 1);
        },
        closeOlahData() {
            this.$refs.form.resetValidation();
            this.$emit("closeOlahDataDialog");
        },
        closePopDialog() {
            this.popTitle = "";
            this.popDialog = false;
        },
    },
};
</script>
